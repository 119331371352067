import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Banner from "../components/Banner/Banner"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const CareerLanding = loadable(() =>
  import("../components/CareerLanding/CareerLanding")
)
const IntroModule = loadable(() =>
  import("../components/IntroModule/IntroModule")
)
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const TileBlockRight = loadable(() =>
  import("../components/TileBlockRight/TileBlockRight")
)

const LandingPageTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const _siteData = data?.strapiSiteConfig
  const Jobs = data?.allStrapiCareer?.edges
  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  })
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <Layout dark popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top" />
      <BreadcrumbModule
        subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
        subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
        parentlabel={
          PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
        }
        parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
        title={PageData.title}
        tag="menu"
      />
      {/* {JSON.stringify(PageData)} */}
      {PageData.banner && (
        <Banner
          title={PageData.title}
          {...PageData.banner}
          id={PageData.strapi_id}
          executeScroll={executeScroll}
          isCareer
          imagetransforms={PageData?.imagetransforms}
        />
      )}

      {PageData?.add_page_modules?.length > 0 &&
        PageData?.add_page_modules?.map((module, index) => {
          return (
            <div key={[index]} className="landing-page">
              {/* {module.strapi_component === "page-modules.text-module" && <PatternBanner
                            tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                            {...module}
                        />} */}
              {module.strapi_component === "page-modules.stat-with-content" && (
                <IntroModule
                  tag="landing"
                  {...module}
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                />
              )}
              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "left" && (
                  <TileBlock
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )}

              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "right" && (
                  <TileBlockRight
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    data={PageData}
                  />
                )}
              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "careers" && (
                  <>
                    <div ref={myRef} id="scroll-margin" />
                    <CareerLanding {...module} jobs={Jobs} />
                  </>
                )}
            </div>
          )
        })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  const _siteData = data?.strapiSiteConfig

  // var schemaSameAs = ''
  // if (siteData?.facebook_link.length > 1) {
  //   schemaSameAs = siteData?.facebook_link + ','
  // }
  // if (siteData?.twitter_link.length > 1) {
  //   schemaSameAs += siteData?.twitter_link + ','
  // }
  // if (siteData?.instagram_link.length > 1) {
  //   schemaSameAs += siteData?.instagram_link + ','
  // }
  // if (siteData?.linkedin_link.length > 1) {
  //   schemaSameAs += siteData?.linkedin_link + ','
  // }

  // var ldJson = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   "url": process.env.GATSBY_SITE_URL,
  //   "name": process.env.GATSBY_SITE_NAME,
  //   "alternateName": process.env.GATSBY_SITE_NAME,
  //   "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
  //   "contactPoint": {
  //     "@type": "ContactPoint",
  //     "telephone": siteData?.mobile_device_phone,
  //     "areaServed": process.env.GATSBY_DEFAULT_AREA,
  //     "contactType": "Sales"
  //   },
  //   "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  // };

  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    >
      {/* <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        /> */}
    </SEO>
  )
}

export default LandingPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_STAT_WITH_CONTENT {
          id
          title
          strapi_component
          stat_with_data {
            stats_description
            stats_title
          }
          content {
            data {
              content
            }
          }
          cta_btn {
            link_type
            cta_label
            cta_link {
              slug
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
          ...ImageAndContentFragment
        }
      }
    }
    allStrapiCareer(filter: { publish: { eq: true } }) {
      edges {
        node {
          title
          slug
          location
          categories {
            blog_name
            slug
          }
        }
      }
    }
  }
`
