import loadable from "@loadable/component"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { PageLinks } from "../../common/site/page-static-links"
import { getAreaPath } from "../../lib/searchUtils"
import { customStyles } from "../DropdownSelectSettings/DropdownSelectSettings"
import MultiSelectInput from "../SearchResults/MultiSelectInput/MultiSelectInput"
import { usePropertyStore } from "../SearchResults/store/propertyStore"

import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./assets/styles/_index.scss"

const Select = loadable(() => import("react-select"))

const SearchBoxFilter = loadable(() =>
  import("../SearchBoxFilter/SearchBoxFilter")
)

const BannerSearch = (props) => {
  const selectedAreas = usePropertyStore((state) => state.selectedAreas)

  // const pageUrl = typeof window !== "undefined" && window.location.href

  const defaultSelect =
    typeof window !== "undefined" &&
    window?.location?.href?.includes("commercial")
      ? {
          value: "commercial",
          label: "Commercial",
        }
      : typeof window !== "undefined" &&
        window?.location?.href?.includes("off-plan")
      ? {
          value: "offplan",
          label: "Off Plan",
        }
      : {
          value: "residential",
          label: "Residential",
        }

  const [propertycat, setPropertyType] = useState(
    typeof window !== "undefined" &&
      window?.location?.href?.includes("commercial")
      ? {
          value: "commercial",
          label: "Commercial",
        }
      : typeof window !== "undefined" &&
        window?.location?.href?.includes("off-plan")
      ? {
          value: "offplan",
          label: "Off Plan",
        }
      : {
          value: "residential",
          label: "Residential",
        }
  )

  const propertyType = [
    { value: "residential", label: "Residential" },
    { value: "commercial", label: "Commercial" },
    { value: "offplan", label: "Off Plan" },
  ]

  const BuyClick = () => {
    const url = getAreaPath(selectedAreas)
    if (url) {
      if (propertycat.value === "residential") {
        navigate(`/properties/for-sale/${url.toLowerCase()}`)
      } else if (propertycat.value === "commercial") {
        navigate(`/commercial-properties/for-sale/${url.toLowerCase()}`)
      } else {
        if(url)
        navigate(`/off-plan-projects/for-sale/${url.toLowerCase()}/country-uae/`)
      }
    } else if (propertycat.value === "commercial") {
      navigate(`/${PageLinks.cbuy}`)
    } else if (propertycat.value === "residential") {
      navigate(`/${PageLinks.buy}/`)
    } else {
      navigate(`/${PageLinks.obuy}/country-uae/`)
    }
  }
  const RentClick = () => {
    const url = getAreaPath(selectedAreas)
    if (url) {
      if (propertycat.value === "residential") {
        navigate(`/properties/for-rent/${url.toLowerCase()}`)
      } else {
        navigate(`/commercial-properties/for-rent/${url.toLowerCase()}`)
      }
    } else if (propertycat.value === "residential") {
      navigate(`/${PageLinks.rent}/`)
    } else {
      navigate(`/${PageLinks.crent}/`)
    }
  }

  const { isMobile } = useDeviceMedia()
  return (
    <div className="banner-search-wrapper">
      {/* <InputGroup className="banner-search-input-group">
        <MultiSelectInput />
        <div className="search-btn">
          <Button
            variant="outline-secondary"
            className="banner-search-btn buy-btn"
            id="basic-addon1"
            onClick={BuyClick}
          >
            Buy
          </Button>
          <Button
            variant="outline-secondary"
            className="banner-search-btn"
            id="basic-ad don1"
            onClick={RentClick}
          >
            Rent
          </Button>
        </div>
      </InputGroup> */}
      <div className="banner-search-filter-container">
        <Select
          options={propertyType}
          defaultValue={defaultSelect}
          value={propertyType.value}
          name="search_type"
          placeholder="Buy"
          className="select-control"
          classNamePrefix="react-select"
          styles={customStyles}
          onChange={(e) => setPropertyType(e)}
          isSearchable={false}
          components={{
            DropdownIndicator: () => <i className="icon filter-arrows-icon" />,
            IndicatorSeparator: () => null,
          }}
        />
        <MultiSelectInput bannerSearch />
        {!isMobile && (
          <div className="search-btn">
            <Button
              variant="outline-secondary"
              className="banner-search-btn buy-btn"
              id="buy"
              onClick={BuyClick}
            >
              Buy
            </Button>
            {propertycat.value !== "offplan" && (
              <Button
                variant="outline-secondary"
                className="banner-search-btn"
                id="rent"
                onClick={RentClick}
              >
                Rent
              </Button>
            )}
          </div>
        )}
      </div>
      {isMobile && (
        <div className="search-btn mobile-banner">
          <Button
            variant="outline-secondary"
            className="banner-search-btn buy-btn"
            id="buy"
            onClick={BuyClick}
          >
            Buy
          </Button>
          <Button
            variant="outline-secondary"
            className="banner-search-btn"
            id="rent"
            onClick={RentClick}
          >
            Rent
          </Button>
        </div>
      )}
    </div>
  )
}

export default BannerSearch
